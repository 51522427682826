

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background-color: #fff; */

  background-image: url('../src//image/background.jpeg'); 
  background-size: contain; /* Растягиваем изображение на всю область фона */
  background-repeat: no-repeat;
  background-position: center bottom;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



ymaps[class$="map"]:first-child:not(:last-child) {
  display: none;
  background: '#000', 
}

.container {
  max-width: full;
  min-width: 1283px;
  padding: 0 70px;
  margin: 0;
}

.header {
  padding: 20px;
  width: 100%;
  height: 38px;
  align-items: center;
  background-color: #275568;
  box-shadow: 2px 2px 3px 3px #dbd7d7;
}

.full__content {
  padding: 20px;
  display: flex;
  height: 85vh;
  width: 98%;
  padding: 20px;
  background-color:antiquewhite;
  justify-content: space-between;
}

/* left dash *************************************************************************************************** */

.left__dashboard{
  padding: 20px;
  width: 47%;
  height: 90%;
  background-color: #fff;
  border-radius: 20px;
  margin-bottom: 30px;
}

.public_title {
  font-size: 40px;
  font-weight: 600;
  color: #275568;
}

.public_title_second {
  font-size:large;
  font-weight: 500;
  color: #275568;
}

.public__button {
  width: 40%;
  height: 60px;
  border-radius: 10px;
  background-color: #275568;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  border: none;
}

.public__image {
  width: 80%;
  height: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto
}


/* right dash *************************************************************************************************** */

.right__dashboard {
  padding: 20px;
  width: 47%;
  height: 90%;
  background-color: #fff;
  border-radius: 20px;
  margin-bottom: 50px;
}

.header__public {
  padding: 20px;
  width: 100%;
  height: 38px;
  align-items: center;
  background-color: #275568;
  box-shadow: 2px 2px 3px 3px #dbd7d7;
}

.dash__title {
  display: flex;
}

.welcome {
  display: grid;
}

.dash_buttons {
  display: grid;
}

.dash__button {
  width: 40%;
  height: 60px;
  border-radius: 10px;
  background-color: #275568;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 10px;
  border: none;
}

.userRole{
  color: white;
}

.adminHeader{
  display: none;
}

.headerRight{
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 35%;
}

.headerButton {
  padding: 8px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  background-color: #eb5449;
  border: none;
  color: #fff;
}



.map-container {
  position: relative;
}

.map-block {
  width: 100%;
  height: 642px;
}

@media (min-width: 1563px) {
  .map-block {
    height: 858px;
    width: 100%;
  }
}

.filter-container{
  display: grid;
  position: absolute;
  border-radius: 10px;
  color: #275568;
  font-weight: 500;
  top: 50px;
  left: 50px;
  background-color: #ffffff;
  box-shadow: 4px 4px 12px 1px #6e909e;
  z-index: 100;
  height: fit-content;
  width: 250px;
  
}

.filter_top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  text-align: center;
  align-items: center;
  padding: 2rm;
  background-color: #275568;
  height: 70px;
  color: #fff;
  margin-bottom: 120px;
}

.dropdown-menu {
  position: relative;
  display: grid;
  width: 250px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-top: 0.5rem;
  margin-bottom: 0.7rem;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.9s ease-in-out;
}

.dropdown-menu.open {
  max-height: fit-content;
}

.dropdown_button {
  cursor: pointer;
  align-items: center;
  text-align: center;
  margin-top: 1rem;
  padding: 0.7rem;
  border-radius: 10px;
  background-color: #275568;
  border-color: #275568;
  font-size: 16px;
  color: #fff;
}



.full {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10%;
}

.title{
  display: flex;
  justify-content: center;
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 20px;
}

.signupScreen {
  align-items: center;
  justify-content: center;
  width: 300px;
  padding: 50px;
  max-height: auto;
  background: rgba(167, 161, 161, 0.85);
  border-radius: 12px;

}

.signupScreen > form > h1 {
  text-align: left;
  margin-bottom: 25px;
}

.signupScreen__gray {
  color: gray;
}

.signupScreen > form > h4 {
  text-align: left;
  margin-top: 30px;
}

.signupScreen > form {
  display: grid;
  flex-direction: column;
}

/* .signupScreen > form > input {
  outline-width: 0;
  height: 40px;
  margin-bottom: 14px;
  border-radius: 5px;
  border: none;
  padding: 5px 15px;
} */

.inputs {
  outline-width: 0;
  height: 40px;
  margin-bottom: 14px;
  border-radius: 5px;
  border: none;
  padding: 5px 15px;
}

.inputsave {
  outline-width: 0;
  height: 22px;
  margin-bottom: 14px;
  border-radius: 5px;
  border: none;
  padding: 5px 15px;
}

.save_button {
  outline-width: 0;
  height: 40px;
  width: 100%;
  margin-bottom: 14px;
  border-radius: 5px;
  border: none;
  padding: 5px 15px;
  background-color: #275568;
  color: #fff;
  font-size: 18px;
}

.roleButton {
  padding: 14px 20px;
  font-size: 1rem;
  color: #fff;
  border-radius: 5px;
  background-color: #275568;
  font-weight: 600;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}



.signupScreen > form > button {
  padding: 16px 20px;
  font-size: 1rem;
  color: #fff;
  border-radius: 5px;
  background-color: #275568;
  font-weight: 600;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}

.signupScreen__link:hover {
  cursor: pointer;
  text-decoration: underline;
}


.form__persist {
  margin-top: 20px;
  color: #213679;
  font-weight: 600;
}


.table {
  padding: 30px;
}

.select-all-checkbox {
  background-color: #f9f9f9;
  border: 2px solid #275568;
  padding: 10px;
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 10px;
}

.select-all-checkbox input {
  margin-right: 8px;
}

.select-all-checkbox:hover {
  background-color: #e7f1ff;
  border-color: #275568;
}

.select-all-checkbox span {
  color: #275568;
}

/* Скрываем стандартный чекбокс */
.custom-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Создаем стилизованный чекбокс */
.custom-checkbox + span::before {
  content: '';
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 8px;
  border: 2px solid #275568; /* Цвет рамки */
  border-radius: 4px;
  background-color: #fff;
  transition: all 0.3s ease;
  vertical-align: middle;
}

/* Стили для галочки, когда чекбокс выбран */
.custom-checkbox:checked + span::before {
  background-color: #275568; /* Цвет фона при выборе */
  border-color: #275568; /* Цвет рамки при выборе */
  position: relative;
}

.custom-checkbox:checked + span::after {
  content: '✓'; /* Символ галочки */
  color: #fff; /* Цвет галочки */
  position: absolute;
  font-size: 14px;
  left: 6px;
  top: 2px;
  font-weight: bold;
}

/* Эффект при наведении */
.custom-checkbox + span:hover::before {
  border-color: #275568;
}
